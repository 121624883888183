<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/cost_36.svg"
            headerText="Setup Your Custom Data Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="step => (isDetails ? updateCollect(step) : createCollect(step))"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-if="collectForm.source.type === 'BigQuery'" v-slot:step-1-input-bigquery>
                <b-row>
                    <b-col md="2" />
                    <b-col>
                        <b-row>
                            <b-col md="8">
                                <span class="small-info-text">
                                    Make sure Your dedicated Service Account has access to the project You store Your
                                    Analytics tables in
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <ChooseBQTable v-model="$v.bigQueryForm.$model" ref="chooseBQTable" depth="table" />
            </template>

            <template v-if="collectForm.source.type === 'Spreadsheet' && templateUrl" v-slot:step-1-input-links>
                <b-row>
                    <b-col md="2"></b-col>
                    <b-col>
                        <b-row>
                            <b-col md="8">
                                <a :href="templateUrl" target="_blank">
                                    <b-button variant="warning">
                                        <img width="20" height="20" src="@/assets/gcp-icons/spreadsheet-icon.png" />
                                        Link to template
                                        <i class="fa fa-external-link-square" />
                                    </b-button>
                                </a>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <br />
            </template>

            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedback"
                            :state="feedback.state"
                            :valid="feedback.valid"
                            :invalid="feedback.invalid"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, requiredIf, minLength} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import ChooseBQTable from '@/components/ChooseBQTable.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
        WitInputGroup,
        ChooseBQTable,
    },
    data() {
        return {
            collectForm: {
                name: null,
                schemaType: null,
                source: {
                    type: 'Spreadsheet',
                    spreadsheetId: null,
                    sheetName: null,
                },
                tokenId: null,
            },
            bigQueryForm: {
                project: null,
                dataset: null,
                table: null,
            },
            dataSources: ['Spreadsheet', 'BigQuery'],
            schemaTypes: ['Cost', 'Budget', 'Custom'],
            currentCollectForm: {},
            feedback: null,
            apiScope: ['https://www.googleapis.com/auth/spreadsheets.readonly'],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            schemaType: {required},
            source: {
                type: {required},
                spreadsheetId: {
                    required: requiredIf(function() {
                        return this.collectForm.source.type === 'Spreadsheet'
                    }),
                },
                sheetName: {
                    required: requiredIf(function() {
                        return this.collectForm.source.type === 'Spreadsheet'
                    }),
                },
            },
            tokenId: {
                required: requiredIf(function() {
                    return this.collectForm.source.type === 'Spreadsheet'
                }),
            },
        },
        bigQueryForm: {
            project: {
                required: requiredIf(function() {
                    return this.collectForm.source.type === 'BigQuery'
                }),
            },
            dataset: {
                required: requiredIf(function() {
                    return this.collectForm.source.type === 'BigQuery'
                }),
            },
            table: {
                required: requiredIf(function() {
                    return this.collectForm.source.type === 'BigQuery'
                }),
            },
        },
    },
    async created() {
        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        formId() {
            return this.isDetails ? 'custom-data-create-form' : 'custom-data-details-form'
        },
        templateUrl() {
            if (this.collectForm.schemaType === 'Cost') {
                return 'https://docs.google.com/spreadsheets/d/1i4GGg45t2OiFfS6jmvU9hT0ppPTaUKmmmHqh-HgcB0I/edit?hl=en&forcehl=1#gid=0'
            }

            if (this.collectForm.schemaType === 'Budget') {
                return 'https://docs.google.com/spreadsheets/d/1i4GGg45t2OiFfS6jmvU9hT0ppPTaUKmmmHqh-HgcB0I/edit?hl=en&forcehl=1#gid=242732581'
            }

            return null
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'schema-type',
                            value: this.$v.collectForm.schemaType.$model,
                            model: this.$v.collectForm.schemaType,
                            type: 'select',
                            options: this.schemaTypes,
                            type: 'select',
                            label: 'dataType',
                            inputLabel: 'Data type',
                            placeholder: 'Select your data type',
                            invalid: 'This field is required',
                            valid: 'Data type is valid',
                        },
                        {
                            name: 'data-source',
                            value: this.$v.collectForm.source.type.$model,
                            model: this.$v.collectForm.source.type,
                            options: this.dataSources.map(source => {
                                return {label: source, value: source}
                            }),
                            type: 'radio',
                            inputLabel: 'Data source',
                        },
                        {
                            name: 'links',
                        },
                        {
                            name: 'spreadsheet-id',
                            value: this.$v.collectForm.source.spreadsheetId.$model,
                            model: this.$v.collectForm.source.spreadsheetId,
                            type: 'text',
                            inputLabel: 'Spreadsheet ID',
                            placeholder: 'Enter your spreadsheet ID',
                            invalid: 'This field is required',
                            valid: 'Spreadsheet ID is valid',
                            vIf: this.collectForm.source.type === 'Spreadsheet',
                        },
                        {
                            name: 'spreadsheet-name',
                            value: this.$v.collectForm.source.sheetName.$model,
                            model: this.$v.collectForm.source.sheetName,
                            type: 'text',
                            inputLabel: 'Sheet name',
                            placeholder: 'Enter your sheet name',
                            invalid: 'This field is required',
                            valid: 'Sheet name is valid',
                            vIf: this.collectForm.source.type === 'Spreadsheet',
                        },
                        {
                            name: 'token',
                            value: this.$v.collectForm.tokenId.$model,
                            model: this.$v.collectForm.tokenId,
                            type: 'google-token',
                            scope: this.apiScope,
                            requestedScopes: this.apiScope,
                            inputLabel: 'Select Google token',
                            vIf: this.collectForm.source.type === 'Spreadsheet',
                        },
                        {
                            name: 'bigquery',
                        },
                    ],
                },
            ]
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/customData/${this.$route.params.id}`
            )
            this.collect = response.data.data

            this.collectForm = {
                name: this.collect.name,
                schemaType: this.collect.schemaType,
                source: {
                    type: this.collect.source.type,
                    spreadsheetId: this.collect.source.spreadsheetId,
                    sheetName: this.collect.source.sheetName,
                    projectId: this.collect.source.projectId,
                    datasetId: this.collect.source.datasetId,
                    tableId: this.collect.source.tableId,
                },
                tokenId: this.collect.tokenRef ? this.collect.tokenRef.id : null,
            }

            if (this.collect.source.type === 'BigQuery') {
                await new Promise(resolve => {
                    const interval = setInterval(interval => {
                        if (this.$refs.chooseBQTable) {
                            clearInterval(interval)
                            resolve()
                        }
                    }, 100)
                })
                const {projectId, datasetId, tableId} = this.collect.source
                await this.$refs.chooseBQTable.fetchAll(projectId, datasetId, tableId)
            }

            this.currentCollectForm = cloneDeep(this.collectForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                if (Object.keys(collectFormDirty).length > 0) {
                    const data = {
                        ...collectFormDirty,
                        tokenId: this.collectForm.tokenId,
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/customData/${this.$route.params.id}`,
                            data
                        )

                        this.feedback = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.$forceUpdate()
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {
                            state: false,
                            invalid: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedback = {
                    state: null,
                    invalid: '',
                }

                const data = {
                    name: this.collectForm.name,
                    schemaType: this.collectForm.schemaType,
                    source: {
                        type: this.collectForm.source.type,
                        spreadsheetId: this.collectForm.source.spreadsheetId,
                        sheetName: this.collectForm.source.sheetName,
                        projectId: this.bigQueryForm.project,
                        datasetId: this.bigQueryForm.dataset,
                        tableId: this.bigQueryForm.table,
                    },
                    tokenId: this.collectForm.tokenId,
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/customData`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$errorHandler.report(exception, 'Could not create form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {
                            state: false,
                            invalid: exception.response.data.data,
                        }
                    })
            }
        },
    },
}
</script>

<style lang="scss"></style>
